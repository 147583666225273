<template>
  <div class="traffic">
    <Header />
    <div class="content">
      <div class="park-title">智慧交通解决方案</div>
      <p class="title-content">
        主要包括设备监测、交通态势、统计决策和诱导发布等模块，贯穿了交通数据从采集到发布的整个全生命周期，构建了一套基于大数据的城市智慧交通整体解决方案。大数据+交通事故管理分析系统，对交通流量、速度、天气、
        事故情况等数据进行关联性分析和统一建模，得到事故发生的风险概率分布态势。该态势随着城市交通流的变化和实时事故情况进行动态更新，并可对高危区域进行实时、针对性管控。
      </p>
      <div style="text-align: center" class="img-box">
        <img
          class="content_img"
          src="https://aierbanjia-official-web.oss-cn-chengdu.aliyuncs.com/web/detail_jt.png"
          alt="智慧交通解决方案"
        />
      </div>
      <div class="footer" @click="jumpTo">下一篇：市域社会治理 >></div>
    </div>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  export default {
    name: 'traffic',
    components: { Header },
    methods:{
      jumpTo() {
        this.$router.push('/details/society')
      }
    }
  }
</script>

<style scoped>
.park-title{
  font-size: 0.37rem;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-bottom: 0.7rem;  
}
.title-content {
  font-size: 0.37rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 0.64rem;
  text-indent: 2em;
}
.img-box{
  margin-top: 1rem;
}
.content_img{
  width: 9.28rem;
  height: 6.21rem;
}
.footer {
  cursor: pointer;
  margin-right: 0.2rem;
  text-align: right;
  width: 100%;
  height: 0.31rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-top: 1rem;
  margin-bottom: 0.6rem;
}
</style>